var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-gray-100" }, [
    _c(
      "div",
      { staticClass: "container mx-auto" },
      [
        _c("h1", { staticClass: "text-center my-8" }, [
          _vm._v("売上アップのために分析ツールを活用しましょう！"),
        ]),
        _vm._m(0),
        _c(
          "h2",
          { staticClass: "text-gray-700 text-center mt-12 mb-6 font-normal" },
          [_vm._v("プレミアムプランに入ると以下のことが可能になります。")]
        ),
        _c("ul", [
          _c(
            "li",
            {
              staticClass:
                "border p-4 shadow-md rounded-md flex items-start border-gray-400 bg-white mt-4",
            },
            [
              _c("div", { staticClass: "h-8 w-8 flex-none" }, [
                _c(
                  "svg",
                  {
                    staticClass: "size-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._m(1),
            ]
          ),
          _c(
            "li",
            {
              staticClass:
                "border p-4 shadow-md rounded-md mt-8 flex items-start border-gray-400 bg-white",
            },
            [
              _c("div", { staticClass: "h-8 w-8 flex-none" }, [
                _c(
                  "svg",
                  {
                    staticClass: "size-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._m(2),
            ]
          ),
          _c(
            "li",
            {
              staticClass:
                "border p-4 shadow-md rounded-md mt-8 flex items-start border-gray-400 bg-white",
            },
            [
              _c("div", { staticClass: "h-8 w-8 flex-none" }, [
                _c(
                  "svg",
                  {
                    staticClass: "size-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._m(3),
            ]
          ),
          _c(
            "li",
            {
              staticClass:
                "border p-4 shadow-md rounded-md mt-8 flex items-start border-gray-400 bg-white",
            },
            [
              _c("div", { staticClass: "h-8 w-8 flex-none" }, [
                _c(
                  "svg",
                  {
                    staticClass: "size-6",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._m(4),
            ]
          ),
        ]),
        _c("div", { staticClass: "mt-8 text-gray-700 p-4 flex" }, [
          _c("div", { staticClass: "h-8 w-8 flex-none" }, [
            _c(
              "svg",
              {
                staticClass: "size-6",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  "stroke-width": "1.5",
                  stroke: "currentColor",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z",
                  },
                }),
              ]
            ),
          ]),
          _vm._m(5),
        ]),
        _c("div", { staticClass: "w-1/2 mx-auto" }, [
          _c(
            "button",
            {
              staticClass:
                "w-full mx-auto h-full mt-12 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
              on: {
                click: function ($event) {
                  return _vm.sendRequestPremium()
                },
              },
            },
            [_vm._v(" プレミアムサービスに申し込む ")]
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "w-1/2 mx-auto mt-6 text-center bg-gray-50 rounded-md p-2 text-gray-700 mb-20 border",
          },
          [
            _c("p", [
              _vm._v(
                "お申込みをご希望の場合には、上のボタンを押してください。"
              ),
            ]),
            _c("p", [
              _vm._v("弊社担当者より、折り返しご連絡させていただきます。"),
            ]),
            _c("p", [
              _vm._v(" サービスへのご質問などがある場合には、 "),
              _c(
                "a",
                {
                  staticClass: "underline inline-block cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.sendInquiryPremium()
                    },
                  },
                },
                [_vm._v("こちらをクリック")]
              ),
              _vm._v(" ください。 "),
            ]),
            _c("p", [
              _vm._v("弊社担当者より、折り返しご連絡させていただきます。"),
            ]),
          ]
        ),
        _c("NoticeModal", {
          attrs: {
            modalName: "sentPremiumRequestModal",
            contents:
              "お申込みありがとうございます。数日以内に、担当者よりメールにてご連絡を差し上げますのでしばらくお待ちください。",
            buttons: ["OK"],
          },
        }),
        _c("NoticeModal", {
          attrs: {
            modalName: "sentPremiumInquiryModal",
            contents:
              "お問い合わせを受け付けました。数日以内に担当者よりメールにてご連絡を差し上げますのでしばらくお待ちください。",
            buttons: ["OK"],
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mx-12" }, [
      _c("p", [
        _vm._v(
          "弊社の今までの海外への作品ライセンシングのノウハウを生かして、より多くの作品が海外で販売されるよう、我々のノウハウの一部にアクセスできるサービスをご用意いたしました。必ず御社お役に立てると考えております。"
        ),
      ]),
      _c("p", [
        _vm._v(
          "1ヶ月1万円と、参加しやすい価格でのご提供になりますので、ぜひ、1度活用をご検討ください。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v("分析ルーツでアクセスデータが閲覧できるようになります。"),
      ]),
      _c("div", { staticClass: "text-gray-500" }, [
        _c("p", [
          _vm._v(
            "どのバイヤーが、御社作品情報を何回見たか、閲覧したバイヤーの所在地、カバーエリア、作品がバイヤーのカートに入っている数、スクリーナーのパスワードクリック数＝再生数などを確認することができます。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "これを把握されることで、具体的なアクションを起こせますので、売り上げアップにつながります。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "具体的なアクションにつきましては、弊社のノウハウの一部を公開させていただきます。"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v("バイヤーへの新規登録作品の自動紹介機能によるアクセス数アップ"),
      ]),
      _c("div", { staticClass: "text-gray-500" }, [
        _c("p", [
          _vm._v(
            "新規にご登録いただいた作品を、弊社の全てのお客様に配信する自動メールに掲載いたします。"
          ),
        ]),
        _c("p", [_vm._v("アクセス数アップは確実です。")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v("納品したデータのダウンロードが可能になります。"),
      ]),
      _c("div", { staticClass: "text-gray-500" }, [
        _c("p", [
          _vm._v(
            "プレミアムサービス会員になれば、納品ページにダウンロード機能が追加されます。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "これにより、これまでは出来なかったオンライフィルムマーケット上にアップロードし保存してあるファイルのダウンロードが可能になります。"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [_vm._v("保管庫としての利用が可能に")]),
      _c("div", { staticClass: "text-gray-500" }, [
        _c("p", [
          _vm._v(
            "プレミアムサービス会員になれば、作品の契約が終わってもオンライフィルムマーケット上にアップロードし保存してあるファイルが削除されなくなります。"
          ),
        ]),
        _c("p", [
          _vm._v(
            "ダウンロード機能と合わせて、データの素材の保管庫としてご活用いただけます。"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v("サービス料：1万円（税別）/月"),
      ]),
      _c("div", { staticClass: "text-gray-500" }, [
        _c("p", [_vm._v("お申込みは、最小で3ヶ月契約～になります。")]),
        _c("p", [
          _vm._v("年間契約の場合には、10万円（税別）でご提供いたします。"),
        ]),
        _c("p", [
          _vm._v(
            "お申込み後に、請求書をお送りさせていただき、ご入金後の利用開始となります。"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }